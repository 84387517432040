import { Forecast } from "./useForecastData";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: Forecast;
}

export const ForecastChart = ({ data }: Props) => {
  const labels = data.DK1.map((d) => d.Time.toLocaleString());

  const chartData = {
    labels,
    datasets: [
      {
        label: "Price",
        data: data.DK1.map((d) => d.PriceDKKwh),
        borderColor: "red",
        backgroundColor: "red",
      },
      {
        label: "Moving average",
        data: data.DK1.map((d) => d.MovingAverage),
        borderColor: "blue",
        backgroundColor: "blue",
      },
    ],
  };

  return <Line data={chartData} options={{ responsive: true }} />;
};
