import { CalculateMovingAverage, useForecastData } from "./useForecastData";
import { ForecastChart } from "./ForecastChart";
import { useQueryClient } from "@tanstack/react-query";
import { ForecastTable } from "./ForecastTable";
import { useState } from "react";

export const ForecastInfo = () => {
  const { data, isLoading, error, dataUpdatedAt } = useForecastData();
  const queryClient = useQueryClient();
  const [hours, setHours] = useState(2);

  if (isLoading) return <div>Loading forecast data...</div>;

  if (error instanceof Error) {
    return <div>Error fetching forecast data: {error.message}</div>;
  }

  if (!data) return <div>No data</div>;

  CalculateMovingAverage(data, hours);

  return (
    <div>
      <h3>Forecast Data</h3>
      <p>
        {`Forecast created: ${data.ForecastCreated.toLocaleString("da-DK")}`}
      </p>
      <p>Data fetched: {new Date(dataUpdatedAt).toLocaleString()}</p>
      <p>Moving average calculated over {hours} hours</p>
      <button onClick={() => setHours(hours - 1)}>Decrease hours</button>
      <button onClick={() => setHours(hours + 1)}>Increase hours</button>
      <button onClick={() => queryClient.invalidateQueries()}>
        Hent ny data
      </button>
      <ForecastChart data={data} />
      <ForecastTable data={data} />
    </div>
  );
};
