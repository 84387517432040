import { Forecast } from "./useForecastData";
import { DataGrid } from "@mui/x-data-grid";

interface Props {
  data: Forecast;
}

export const ForecastTable = ({ data }: Props) => (
  <DataGrid
    rows={data.DK1.map((d) => ({
      id: d.Time.toString(),
      col1: d.Time.toLocaleString("da-DK"),
      col2: d.Price,
      col3: d.Tarrif,
      col4: d.PriceDKKwh,
      col5: d.MovingAverage,
    }))}
    columns={[
      { field: "col1", headerName: "Time", width: 200 },
      { field: "col2", headerName: "Spotprice" },
      { field: "col3", headerName: "Tarrif" },
      { field: "col4", headerName: "Price" },
      { field: "col5", headerName: "Moving average", width: 200 },
    ]}
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 50 },
      },
    }}
  />
);
