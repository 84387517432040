import { useQuery } from "@tanstack/react-query";

export interface PriceDataPointRM {
  Time: string;
  Price: number;
}

export interface PriceDataPoint {
  Time: Date;
  Price: number;
  Tarrif: number;
  PriceDKKwh: number;
  MovingAverage?: number;
}

export interface ForecastRM {
  "Forecast created": string;
  DK1: PriceDataPointRM[];
  DK2: PriceDataPointRM[];
}

export interface Forecast {
  ForecastCreated: Date;
  DK1: PriceDataPoint[];
}

const RoundNumber = (input: number, decimals = 2) =>
  Number(input.toFixed(decimals));

const CalculateTarrif = (_date: Date) => 33;
// 20 >= date.getHours() && date.getHours() >= 17 ? 84 : 33;

const CalculateNormalPrice = (rm: PriceDataPointRM) =>
  RoundNumber(rm.Price + CalculateTarrif(new Date(rm.Time)));

export const CalculateMovingAverage = (forecast: Forecast, hours: number) => {
  for (let i = 0; i < forecast.DK1.length; i++) {
    let sum: number = 0;
    let count: number = 0;
    for (let j = 0; j < hours; j++) {
      if (i + j < forecast.DK1.length) {
        sum += forecast.DK1[i + j].PriceDKKwh;
        count++;
      }
    }
    forecast.DK1[i].MovingAverage = RoundNumber(sum / count, 2);
  }
};

const fetchForecastData = async (): Promise<Forecast> => {
  const response = await fetch(
    "https://raw.githubusercontent.com/solmoller/Spotprisprognose/main/prognose.json"
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data: ForecastRM = await response.json();
  data.DK1.forEach((d) => (d.Time = d.Time + "Z"));

  return {
    ForecastCreated: new Date(data["Forecast created"]),
    DK1: data.DK1.map(
      (d) =>
        ({
          Time: new Date(d.Time),
          Price: d.Price,
          Tarrif: CalculateTarrif(new Date(d.Time)),
          PriceDKKwh: CalculateNormalPrice(d),
        } as PriceDataPoint)
    ),
  };
};

export const useForecastData = () => {
  return useQuery({ queryKey: ["forecastData"], queryFn: fetchForecastData });
};
