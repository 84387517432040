import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ForecastInfo } from "./ForecastInfo";
import { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Session } from "@supabase/supabase-js";

const supabase = createClient(
  "https://fktkuqqrzsvqvhdttdok.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZrdGt1cXFyenN2cXZoZHR0ZG9rIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzUxOTA3NzksImV4cCI6MTk5MDc2Njc3OX0.nqh2csSw7-CH4YDh0Ey4Qnpmp05mviiMRV1t3HXN9gI"
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 48, // 48 hours
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
    },
  },
});

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
        />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ForecastInfo />
    </QueryClientProvider>
  );
}

export default App;
